import { useRoute } from 'vue-router';
import ClipboardJS from 'clipboard';
import { env } from 'md-base-tools/env';
import { get } from '@/utils/apiBase.js';
import { showSuccessToast } from 'vant';
import { mdToast } from '../../utils/tools';
export default {
  data() {
    return {
      placeholder: require('@assets/images/placeholder/default_1x1@2x.png'),
      copyboard: null,
      dialog_show: false,
      dialog_src: '',
      dialog_btn: [],
      dialog_used: 1
    };
  },
  components: {},
  async setup() {
    const route = useRoute();
    const expressNo = route.query.express_no ? route.query.express_no : null;
    const expressName = route.query.express_name ? route.query.express_name : null;
    const postage_order_no = route.query.postage_order_no || '';
    let logisticsList = [];
    const res = await get('/apis/card-wallet/logistics/list', {
      postage_order_no: postage_order_no
    });
    if (res.status !== 0) return;
    res.data.forEach(ele => {
      if (ele.express_no == expressNo) {
        logisticsList = ele.logistics_info;
      }
    });
    return {
      logisticsList,
      expressName,
      expressNo
    };
  },
  watch: {},
  computed: {
    isShow() {
      return env.isModianIos() || env.isModianAndroid();
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = 0;
    window.userHeader.titleText("物流详情");
    var target = this.$refs.copyboard;
    this.copyboard = new ClipboardJS(target, {
      text(target) {
        return target.getAttribute("copyData");
      }
    });
    this.copyboard.on("success", () => {
      // eslint-disable-next-line
      showSuccessToast("复制成功");
    });
  },
  methods: {
    copy() {
      document.getElementsByTagName("input")[0].select();
      try {
        if (document.execCommand("copy", false, null)) {
          showSuccessToast("复制成功");
        } else {
          // IOS不支持时候重新初始化Clipboard
          var copyTarget = this.$refs.copyboard;
          var copyboard = new ClipboardJS(copyTarget, {
            text(copyTarget) {
              return copyTarget.getAttribute("copyData");
            }
          });
          copyboard.on("success", () => {
            showSuccessToast("复制成功");
          });
        }
      } catch (err) {
        mdToast(err);
      }
    }
  }
};